const $ = require("jquery")

function calcAmount(target, display_target_id) {
  var amount = target.val().replace(/^[0]+/,'');
  var digit = ['', '万', '億', '兆', '京'];
  var result = '';
  if (amount) {
    var amounts = String(amount).replace(/(\d)(?=(\d\d\d\d)+$)/g, "$1,").split(",").reverse();
    for (var i=0;i < amounts.length;i++) {
      if (!amounts[i].match(/^[0]+$/)) {
        // 先頭の0を削除
        amounts[i] = amounts[i].replace(/^[0]+/g, "");
        result = amounts[i] + digit[i] + result;
      }
    }
    $("#" + display_target_id).html(result);
  }
}

function checkAmount(event_target, target_id, confirm_message) {
  var amount = $("#" + target_id).val();
  if (parseInt(amount) >= 500000) {
    if (confirm(confirm_message)) {
      return true;
    }
  } else {
    return true;
  }
  var submitButton = event_target.find('input[type="submit"]');
  setTimeout(function(){
    // onSubmit実行後にボタンが非活性になるので戻す
    submitButton.prop("disabled", false);
  }, 100);
  return false;
}

$(function() { 'use strict';
  var chargeAmount = $('#charge_amount');

  chargeAmount.on('input', function () {
    calcAmount(chargeAmount, 'charge_amount_text');
  });

  var adminChargeForm = $('#admin_charge');
  adminChargeForm.on('submit', function () {
    return checkAmount(adminChargeForm, 'charge_amount', '高額のチャージです。チャージ金額が正しいことを確認してください。');
  });
  adminChargeForm.find('input[type=submit]:disabled').prop('disabled', false);
});
