const $ = require("jquery")

// init bootstrap tools
$(function() { 'use strict';
  $('[data-toggle="tooltip"]').tooltip();
});

// countup
$(function() { 'use strict';
  $('.js-count-anim').each(function () {
    let $this = $(this);
    if($this.text() === "＊＊＊"){
      return
    }
    $this.prop('c', 0).animate({
      c: $this.text()
    }, {
      duration: 1000,
      easing: 'linear',
      step: function (now) {
        $this.text(Math.ceil(now).toLocaleString());
      }
    });
  });
});

// affix nav
$(function() { 'use strict';
  $('.js-affix-nav').each(function() {
    let $elem = $(this);
    let posY = $elem.offset().top;
    let height = $elem.outerHeight();
    let $spacer = $('<div class="spacer"></div>')
      .hide()
      .height(height)
      .insertAfter($elem);

    $(window).on('scroll', function() {
      if ($(this).scrollTop() > posY ) {
        $elem.addClass('is-fixed');
        $spacer.show();
      } else {
        $elem.removeClass('is-fixed');
        $spacer.hide();
      }
    });
  });
});

// https://news.toranoana.jp/wp-json/api/v1/toracoin
// キャンペーンバナーを取得
$(function() { 'use strict';
  if (!$('#js-campaign-items-container').length) {
    return;
  }
  let $cont = $('#js-campaign-items-container');
  let url = 'https://news.toranoana.jp/wp-json/api/v1/toracoin';

  setItems();

  function setItems() {
    $.ajax({
      url: url,
      type: 'GET',
      dataType: 'json',
      data: {},
      timeout: 10000,
      cache: false,
    })
    .done(function(res){
      if (!res.campaign) {
        $('#campaign').hide();
      } else {
        res.campaign.forEach(function(item) {
          let html = '';
          html += '<div class="col-12 col-sm-6 mb-3">';
            html += '<a class="item" target="_blank" href="' + item.url + '" title="' + item.title + '">';
              html += '<div class="card">';
                html += '<img class="card-img-top" src="' + item.image.lg + '" alt="' + item.title + '" />';
                html += '<div class="card-body">' + item.title + '</div>';
              html += '</div>';
            html += '</a>';
          html += '</div>';
          $(html).appendTo($cont);
        });
      }
    })
    .fail(function(res){
      console.log(res);
    });
  }
});

// よくある質問を取得
$(function() { 'use strict';
  if (!$('#js-faq-items-container').length) {
    return;
  }
  let $cont = $('#js-faq-items-container');
  let url = 'https://news.toranoana.jp/wp-json/api/v1/toracoin';

  setItems();

  function setItems() {
    $.ajax({
      url: url,
      type: 'GET',
      dataType: 'json',
      data: {},
      timeout: 10000,
      cache: false,
    })
    .done(function(res){
      if (!res.faq) {
        return
      };

      res.faq.forEach(function(item, index) {
        let html = '';
        html += '<li class="faq-item">';
          html += '<div class="question collapsed" data-toggle="collapse" href="#answer-' + index + '"><span>Q.' + item.question + '</span><i class="fa fa-angle-down icon"></i></div>';
          html += '<div class="collapse" id="answer-' + index + '"><div class="answer">' + item.answer + '</div></div>';
        html += '</li>';
        $(html).appendTo($cont);
      });
    })
    .fail(function(res){
      console.log(res);
    });
  }
});

// ヘッダーアラートを取得
$(function() { 'use strict';
  if (!$('#js-notification-items-container').length) {
    return;
  }
  let $cont = $('#js-notification-items-container');
  let url = 'https://news.toranoana.jp/wp-json/api/v1/toracoin';

  setItems();

  function setItems() {
    $.ajax({
      url: url,
      type: 'GET',
      dataType: 'json',
      data: {},
      timeout: 10000,
      cache: false,
    })
    .done(function(res){
      if (!res.notification) {
        return
      };

      res.notification.forEach(function(item) {
        let html = '';
        if (item.url && item.url != '') {
          html += '<a class="item" target="_blank" href="' + item.url + '" title="' + item.title + '">';
            html += '<span><i class="fa fa-info-circle mr-1"></i>' + item.title + '</span>';
          html += '</a>';
        } else {
          html += '<span><i class="fa fa-info-circle mr-1"></i>' + item.title + '</span>';
        }
        $(html).appendTo($cont);
      });
    })
    .fail(function(res){
      console.log(res);
    });
  }
});

// PayPay用バナーを各ページで取得
$(function() { 'use strict';
  let $cont = $('#js-bnr-items-container');
  let url = 'https://news.toranoana.jp/wp-json/api/v1/toracoin';
  let page = $cont.data('page');
  let now = new Date();

  setItems();

  function setItems() {
    $.ajax({
      url: url,
      type: 'GET',
      dataType: 'json',
      data: {},
      timeout: 10000,
      cache: false,
    })
    .done(function(res){
      if (!res.bnr || !res.bnr[page]) {
        return
      };
      res.bnr[page].forEach(function(item) {
        // 開始と終了をみる
        // 本来はサーバー側で処理したいところだが、jsonをキャッシュしている都合上、開発コストがかかるため、フロントで処理する
        let startTime = new Date(item.start_time);
        let endTime = new Date(item.end_time);

        if (startTime.getTime() <= now.getTime() && now.getTime() <= endTime.getTime()) {
          let html = '';
          if (item.url) {
            html += '<a target="_blank" href="' + item.url + '" title="' + item.title + '">';
              html += '<img class="img-fluid" src="' + item.image.lg + '" alt="' + item.title + '" />';
            html += '</a>';
          } else {
            html += '<div>';
              html += '<img class="img-fluid" src="' + item.image.lg + '" alt="' + item.title + '" />';
            html += '</div>';
          }
          $(html).appendTo($cont);
        }
      });
    })
    .fail(function(res){
      console.log(res);
    });
  }
});

$(function() { 'use strict';
  $(document).ready(function(){
    userAddressSubmitDisable();
  });

  $(document).on('change', '#policy', function () {
    userAddressSubmitDisable();
  });

  var userAddressSubmitDisable = function () {
    if ($('#policy').prop('checked')){
      $('.btn-user-address').prop('disabled', false);
    } else {
      $('.btn-user-address').prop('disabled', true);
    }
  }
});

// リアルタイムチャージフォーム
$(function() { 'use strict';
  if (!$('#auto-charge-form').length) {
    return;
  }
  const $toggleCont = $('.js-service-check-cont');

  // アクセス時
  toggleServices($('.js-method-radio:checked').val())

  // チャージ方法ラジオボタンを変更
  $('.js-method-radio').on('change', function() {
    const val = $(this).val();
    toggleServices(val);
  });

  // not-selectedの場合は、サービス設定ブロックを非表示
  function toggleServices(val) {
    switch (val) {
      case '0':
        $toggleCont.hide();
        break;
      default:
        $toggleCont.show();
    }
  }
});

// トップページスライド
$(function() {
  if (!$('#js-bnr-slides-container').length) {
    return;
  }

  const $cont = $('#js-bnr-slides-container');

  $cont.on('init', function(event, slick) {
    setup();
  });

  $cont.slick({
    autoplay: true,
    autoplaySpeed: 7000,
    dots: true,
    arrows: true,
    infinite: true,
    slidesToShow: 3,
    slidesToScroll: 3,
    responsive: [
      {
        breakpoint: 1299,
        settings: {
          arrows: true,
          slidesToShow: 3,
          slidesToScroll: 3,
        }
      },
      {
        breakpoint: 991,
        settings: {
          arrows: true,
          slidesToShow: 2,
          slidesToScroll: 2,
        }
      },
      {
        breakpoint: 767,
        settings: {
          arrows: false,
          slidesToShow: 1,
          slidesToScroll: 1,
        }
      },
      {
        breakpoint: 480,
        settings: {
          arrows: false,
          slidesToShow: 1,
          slidesToScroll: 1,
          centerMode: true,
        }
      }
    ]
  });

  function setup() {
    const url = $cont.data('url');
    
    $.ajax({
      url: url,
      type: 'GET',
      dataType: 'json',
      data: {},
      timeout: 10000,
      cache: false,
    })
    .done(function(res){
      if (!res.campaign) {
        return
      };
      res.campaign.forEach(function(item) {
        let html = '';
        if (item.url) {
          html += '<div><a class="item" target="_blank" href="' + item.url + '" title="' + item.title + '">';
            html += '<div class="card">';
              html += '<img class="card-img-top img-fluid lazyload" width="600" height="300" data-src="' + item.image.md + '" src="/images/img_nowloading_2x1.png" alt="' + item.title + '" />';
              html += '<div class="card-body font-weight-bold">' + item.title + '</div>';
            html += '</div>';
          html += '</a></div>';
        }
        $cont.slick('slickAdd', html);
      });
    })
    .fail(function(){

    });
  }

});

// リアルタイムチャージLP
$(function() { 'use strict';
  if (!$(".help-real_time_charge").length) {
    return;
  } 
  
  var btn = $('.real-time-charge-scrollbtn');
  $(window).on('load scroll', function(){
    if($(this).scrollTop() > 300) {
      btn.addClass('active');
    }else{
      btn.removeClass('active');
    }
  });

});

// リアルタイムチャージヘッダーの高さを引いたナビスクロール
$(function() { 'use strict';
  if (!$(".help-real_time_charge").length) {
    return;
  }
 
  $('#page-link a[href*="#"]').click(function () {
    var elmHash = $(this).attr('href'); 
    var pos = $(elmHash).offset().top-70;
    $('body,html').animate({scrollTop: pos}, 100); 
    return false;
  });

  $('#page-link-sp a[href*="#"]').click(function () {
    var elmHash = $(this).attr('href'); 
    var pos = $(elmHash).offset().top-70;
    $('body,html').animate({scrollTop: pos}, 100); 
    return false;
  });

});

// ヘッダーメニュー
$(function() { 'use strict';
  $('.js-header-dropdown-nav')
    .on('show.bs.dropdown', function () {
      $(this).find('.dropdown-menu').animate({ scrollTop: 0 }, 'slow');
    });
});
