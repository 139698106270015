function submitForm(e) {
  const id = e.target.getAttribute('data-id');
  const form = document.getElementById(`operation_request_form_${id}`);

  const memo = window.prompt('申請理由を記載ください。')

  form.querySelector('#memo').value = memo;

  if(memo && memo.length > 0){
    return form.submit();
  }
  alert("申請理由の記載が適切でないので、申請をキャンセルしました")
}

document.addEventListener('DOMContentLoaded', function() {
  const elements = document.querySelectorAll('.operation-request-btn')

  elements.forEach((element) => {
    element.addEventListener('click', submitForm);
  })
});
