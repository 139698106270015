const $ = require("jquery");

function submitToken(card_num, exp_month, exp_year, sc, cardholder_name, invoke) {
  var data = {};
  data.token_api_key = document.getElementById("token_api_key").value;
  data.card_number = card_num;
  data.cardholder_name = cardholder_name;
  data.card_expire = exp_month + "/" + exp_year;
  data.security_code = sc;
  data.lang = "ja";

  getToken(data, setMpiToken);
}

function getToken(data, invoke) {
  var url = document.getElementById("token_api_url").value;
  var xhr = new XMLHttpRequest();
  xhr.open("POST", url, true);
  xhr.setRequestHeader("Accept", "application/json");
  xhr.setRequestHeader("Content-Type", "application/json; charset=utf-8");
  xhr.addEventListener("loadend", function () {
    if (xhr.status === 0) {
      alert("トークンサーバーとの接続に失敗しました");
      return;
    }
    var response = JSON.parse(xhr.response);
    if (xhr.status === 200) {
      invoke(response.token, data);
    } else {
      $("#alerts").append(
        '<div class="container"><div class="alert alert-danger" role="alert">このカードはご利用になれません。カード会社にお問い合わせいただくか、別のクレジットカードをご利用ください。</div></div>'
      );
      $("body, html").scrollTop(0);
      return;
    }
  });
  xhr.send(JSON.stringify(data));
}

function setMpiToken(token, data) {
  document.getElementById("mpi_token").value = token;
  getToken(data, serverSend);
}

function serverSend(token) {
  document.getElementById("token").value = token;
  document.getElementById("tokens").submit();
  return true;
}

// 現状ボタンで呼び出されて起動
function creditSet() {
  var cardholder_name = $("#tmp_cardholder_name").val();
  var card_num = $("#number").val();
  var exp_month = $("#deadline_m").val();
  var exp_year = $("#deadline_y").val();
  var sc = $("#secrity_code").val();
  var msgs = [];

  //バリデーション
  $("#alerts").empty();

  if (cardholder_name.length === 0) {
    msgs.push("クレジットカード名義人が入力されていません");
  } else if (cardholder_name.length < 2 || 26 < cardholder_name.length) {
    msgs.push("クレジットカード名義人の文字数は26文字までです");
  } else if (cardholder_name.search(/^[A-Z ]+$/) !== 0) {
    msgs.push("大文字アルファベットと半角スペース以外の文字が使用されています");
  }

  //number
  //要件：必須、桁数(16)、数字列
  if (card_num.length === 0) {
    msgs.push("カード番号が入力されていません");
  } else if (card_num.length < 14 || 16 < card_num.length) {
    msgs.push("カード番号の文字数が不正です");
  } else if (isNaN(card_num)) {
    msgs.push("カード番号に不正な文字が使用されています");
  }

  //deadline_m
  //要件：必須、入力済み
  if (exp_month === "") {
    msgs.push("有効期限月が未選択です");
  }

  //deadline_y
  //必須、入力済み
  if (exp_year === "") {
    msgs.push("有効期限年が未選択です");
  }

  //secrity_code
  //要件：必須、桁数(3,4)、数字列
  if (sc.length === 0) {
    msgs.push("セキュリティコードが入力されていません");
  } else if (isNaN(sc)) {
    msgs.push("セキュリティコードに不正な文字が使用されています");
  } else if (sc.length < 3 || 4 < sc.length) {
    msgs.push("セキュリティコードの文字数が不正です");
  }

  if (msgs.length === 0) {
    document.getElementById("cardholder_name").value = cardholder_name ;
    submitToken(card_num, exp_month, exp_year, sc, cardholder_name, serverSend);
  } else {
    $("#alerts").append(
      '<div class="container"><div class="alert alert-danger" role="alert" id="alert_msg"></div></div>'
    );
    msgs.forEach(function (msg) {
      $("#alert_msg").append(msg + "</br>");
    });
    $("body, html").scrollTop(0);
  }
}

function creditCheckAndSet() {
  if (typeof window.aerosmith === "undefined") {
    // aerosmithが存在しない場合は処理をスキップ
    creditSet();
  } else {
    window.aerosmith.checkScore(
      function (res) {
        if (res === "LRT" || res === "BIAT") {
          // LRT = リスク低
          // BIAT = リスク中かつ、認証に成功
          // checkScoreが通った場合はVeritransのTokenをセットしてフォームを送信出来るようにする
          creditSet();
        }
      },
      function (err) {
        if (err === "failed") {
          // TODO: リダイレクト先は考慮必要、動作確認できたら newと editで差し替える
          window.location.href = "/";
        }
      }
    );
  }
}

//windowオブジェクトに関連付ける
window.submitToken = submitToken;
window.creditSet = creditSet;

$(function() { 'use strict';
  $(".js-credit-check-and-set").on('click', creditCheckAndSet);
});
