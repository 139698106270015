function submitForm(e) {
  const id = e.target.getAttribute('data-id');
  const form = document.getElementById(`operation_request_form_${id}`);

  const memo = window.prompt('却下理由を記載ください。')

  form.querySelector('#rejected_reason').value = memo;

  if(memo && memo.length > 0){
    return form.submit();
  }
  alert("申請理由の記載が適切でないので、申請をキャンセルしました")
}

document.addEventListener('DOMContentLoaded', function() {
  const element = document.querySelector('#reject-operation-request-btn')
  element.addEventListener('click', submitForm);
});
