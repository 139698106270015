const $ = require("jquery")

function orderSubmit() {
    var sc = $("#secrity_code").val()
    var msgs = []

    //バリデーションメッセージリセット
    $("#alerts").empty()

    //secrity_code
    //要件：必須、桁数(3,4)、数字列
    if (sc.length === 0) {
        msgs.push("セキュリティコードが入力されていません")
    }
    else {
        if (isNaN(sc)) {
            msgs.push("セキュリティコードに不正な文字が使用されています")
        }
        if (sc.length < 3 || 4 < sc.length) {
            msgs.push("セキュリティコードの文字数が不正です")
        }
    }

    if (msgs.length === 0) {
        document.getElementById("order").submit()
    }
    else {
        $("#alerts").append('<div class="container"><div class="alert alert-danger" role="alert" id="alert_msg"></div></div>')
        msgs.forEach(function (msg) {
            $("#alert_msg").append(msg + "</br>")
        });
        $('body, html').scrollTop(0);
    }
}

$(function() { 'use strict';
  $(".js-order-submit").on('click', orderSubmit);
});
