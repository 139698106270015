const $ = require("jquery")

// 通知設定画面用ファンクション
function notificationFilter() {
  $("#alerts").empty();
  const self = $(this);
  const isAddFilter = !self.prop("checked");
  let path = 'remove';
  if (isAddFilter) {
    path  = 'add';
  }
  const url = location.origin + '/notifications/' + path

  $.ajax({
    url: url,
    type: 'POST',
    dataType: 'json',
    data: { kind: self.val() },
    timeout: 10000,
    cache: false,
  }).done(function(){
    addMessage(true, '設定を変更しました');
  })
  .fail(function(){
    addMessage(false, '設定変更に失敗しました');
  });
}

function addMessage(isSuccess, message) {
  if (isSuccess) {
    $("#alerts").append(
      '<div class="container"><div class="alert alert-primary" role="alert" id="alert_msg">' + message +'</div></div>'
    );
    return;
  }
  $("#alerts").append(
    '<div class="container"><div class="alert alert-danger" role="alert" id="alert_msg">' + message +'</div></div>'
  );
}

// メール・通知許諾用ファンクション
async function setNotificationConsent(isApprove) {
  if (isApprove == null) {
    return false;
  }

  const consentParam = (isApprove ? 'approved' : 'rejected');
  const url = location.origin + '/notifications/consent'

  let updateResult = false;
  await $.ajax({
    url: url,
    type: 'POST',
    dataType: 'json',
    data: { notification_consent: consentParam },
    timeout: 10000,
    cache: false,
  }).done(function(){
    updateResult = true;
  })
  .fail(function(){
    updateResult = false;
  });
  return updateResult;
}

window.setNotificationConsent = setNotificationConsent;

async function setConsentFromModal(isApprove) {
  const result = await setNotificationConsent(isApprove);
  $("#alerts").empty();
  let message = 'メール・通知許諾に失敗しました';
  if (result) {
    message = 'メール・通知許諾を設定しました';
  }
  addMessage(result, message);
  $('#notificationConsentModal').modal('hide');
  $(window).scrollTop(0);
}

function showNotificationModal() {
  $('#notificationConsentModal').modal('show');
}

window.showNotificationModal = showNotificationModal;

async function setNotificationConsentToggle() {
  const isApprove = $("#notification_consent").prop("checked");

  $("#alerts").empty();
  const result = await setNotificationConsent(isApprove);
  if (!result) {
    addMessage(result, '設定変更に失敗しました');
    $(window).scrollTop(0);
    return;
  }

  addMessage(result, '設定を変更しました');
  $('li.list-group-item').toggle();
}

function initDisplayNotificationFilter() {
  const isApprove = $("#notification_consent").prop("checked");

  if (isApprove) {
    $('li.list-group-item').show();
    return;
  }
  $('li.list-group-item').hide();
}

window.initDisplayNotificationFilter = initDisplayNotificationFilter;

$(function() { 'use strict';
  $('#notification_consent').on('change', setNotificationConsentToggle);
  $('.js-notification-kind-item').on('change', notificationFilter);

  var modal = $('#notificationConsentModal');
  modal.find('.js-notification-accept').on('click', function () {
    setConsentFromModal(true);
  });
  modal.find('.js-notification-reject').on('click', function () {
    setConsentFromModal(false);
  });
});
