'use strict';
$(function() {
  if (!$('#lottery-result-modal').length) {
    return;
  };

  const $modal = $('#lottery-result-modal');
  
  init();

  function init() {
    // モーダルを表示
    $modal.modal('show');
  }
});